<template>
    <div class="reportEatUrgeFood defaultReport">
        <div class="content-box clearfix">
            <div class="panel-box top-info-box">
                <el-row>
                    <el-col :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
                        <span class="lable-text">统计日期：{{data?.Rpt_Date}} </span>
                    </el-col>
                </el-row>
                <div class="right-bnts">
                    <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
                </div>
            </div>
            <div class="table-box grey-table" ref="tableBox">
                <el-table ref="elTable" class="el-table--scrollable-y"  :data="tableListPage" 
                 border 
                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" :default-sort = "{prop: 'date', order: 'descending'}">
                    <el-table-column prop="Rpt_Date" label="营业日期" min-width="80" :formatter="shortDateFormat"></el-table-column>
                    <el-table-column prop="Eat_ID" label="账单号" min-width="160"></el-table-column>
                    <el-table-column prop="Eat_DeskName" label="桌号" min-width="80"></el-table-column>
                    <el-table-column prop="Eat_XFCode" label="菜品编码" min-width="80"></el-table-column>
                    <el-table-column prop="Eat_XFName" label="菜品名称" min-width="80"></el-table-column>
                    <el-table-column prop="Eat_Number" label="数量" min-width="70" align="right" data-format="number"></el-table-column>
                    <el-table-column prop="Eat_XFSize" label="单位" min-width="70"></el-table-column>
                    <el-table-column prop="Eat_Czy" label="点菜人" min-width="80"></el-table-column>
                    <el-table-column prop="Eat_Time" label="点单时间" min-width="130" :formatter="dateFormat"></el-table-column>
                    <el-table-column prop="Begin_Time" label="起菜时间" min-width="130" :formatter="dateFormat"></el-table-column>
                    <el-table-column prop="Eat_RushCounts" label="催菜次数" min-width="70"  align="right"></el-table-column>
                    <el-table-column prop="Last_RushTime" label="催菜时间" min-width="130" :formatter="dateFormat"></el-table-column>
                    <el-table-column prop="Rush_Czy" label="催菜人" min-width="70"></el-table-column>
                    <el-table-column prop="RushMin" label="催菜间隔/分" min-width="80"  align="right" data-format="number"></el-table-column>
                </el-table>
            </div>
            <table-page-btn class="fy" :data="tableList" 
                :default-index="currentPage" 
                @current-change="(index)=>currentPage=index"
                @page-change="(list)=>tableListPage=list" 
                :page-size="pagesize"></table-page-btn>
        </div>
        
     </div>
</template>
<script>

import FileSaver from 'file-saver'
import XLSX from 'xlsx'

/**催菜分析表 */
export default {
    name:'reportEatUrgeFood',
    props:{
        data:{
            type:Object,
            default:null
        }
    },
    data(){
        return {
            userInfo:{},
            /**表格数据 */ 
            currentPage:1,
            tableList:[],
            tableListPage:[],
            pagesize:9,//每页的数据条数
        }
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
    },
    watch:{
        data:{
            handler(){
                if(this.data){
                   this.$nextTick(()=>{
                        this.GetBillCheck();
                   })
                }
            },
            immediate:true,
        }
    },
    methods:{
        /**计算 一页显示多少条数据 */
        setTablePageSize(){
            if(this.$refs.tableBox){
                this.$nextTick(()=>{
                    let height=this.$refs.tableBox.clientHeight - 80;
                    this.pagesize=parseInt(height/50);
                })
            }
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            if(this.$refs.elTable){
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.elTable,
                    titleName:"催菜分析表",
                    list:this.tableList,
                    headArr:[
                        [
                            {value:'统计日期：'+this.data?.Rpt_Date,col:3}, 
                        ],
                    ]
                })
            }

            
        },
         /*时间格式 */
        shortDateFormat(row, column, cellValue){
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd');
        },
        /*时间格式 */
        dateFormat(row, column, cellValue){
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm:ss');
        },
         /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /**查询 */
        GetBillCheck(){
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Rpt_Date:this.data?.Rpt_Date
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            this.$httpAES.post("Bestech.CloudPos.FoodRushDetailReport",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableList=d.ResponseBody
                    this.setTablePageSize()
                    this.currentPage=1
                }else{
                    this.tableList=[];
                    this.currentPage=1
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableList=[];
                this.currentPage=1
                loading.close();
                this.$message.error(e.message);
                console.log(e);
            })
        }
    }
}
</script>

<style lang="scss">
@import './reportEatUrgeFood.scss'
</style>